<template lang="pug">
include ../../../configs/template
form.row
  div(v-if="[69, 82].includes(statement.status_document) || checkAccess('admin')").col-6
    +select('body.status_document','mappingStatuses','status','labelName')
  div(v-if="checkAccess('admin') || (checkAccess('crewingManager') && body.status_document === 67)").col-6
    +date-picker-validation('body.date_end_proxy','contractDateEnd','dateEndObject','["required", "minValue", "maxValue"]')(
      min="1900-01-01" max="2200-01-01")
  FileDropZone(
    v-if="checkAccess('admin') || [69, 82].includes(body.status_document)"
    ref="mediaContent").col-12
  div.w-100.d-flex.justify-center.mt-3
    v-btn(@click="onSubmit" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { crewingManagerStatements } from '@/mixins/validationRules'
export default {
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: {
        date_end_proxy: null,
        status_document: null
      },
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose']),
    ...mapState({
      statement: state => state.statement.crewingManagerStatementById,
      isLoading: state => state.statement.isLoading,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    dateEndObject () {
      return this.body.date_end_proxy ? new Date(this.body.date_end_proxy) : null
    },
    mappingStatuses () {
      let statuses = this.statusChoose('StatementCrewingManagerSailor')
      if (checkAccess('crewingManager')) return statuses.filter(value => ![82, 83].includes(value.id))
      return statuses
    }
  },
  mounted () {
    this.fieldsAutocomplete()
  },
  validations () { return crewingManagerStatements(this) },
  methods: {
    ...mapActions(['updateCrewingManagerStatement', 'uploadFilesCrewingManagerStatement']),

    fieldsAutocomplete () { // Autocomplete statement information
      const statementInformation = JSON.parse(JSON.stringify(this.statement))
      this.body = Object.keys(this.body).reduce((t, k) => ({ ...t, [k]: statementInformation[k] }), {})
    },

    async onSubmit () {
      if (this.$v.$invalid) return this.$v.$touch()

      const fileBody = new FormData()
      if (this.$refs.mediaContent) {
        for (const photo of this.$refs.mediaContent.filesArray) {
          fileBody.append('photo', photo)
        }
      }

      const response = await this.updateCrewingManagerStatement({ ...this.$route.params, body: this.body })
      if ([200, 201].includes(response?.code)) {
        await this.uploadFilesCrewingManagerStatement({ id: response.data.id, body: fileBody })
        this.$notification.success('editedCrewingManagerStatementFromSailor')
      }
    }
  }
}
</script>
